import React from 'react';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

export const Top = () => {
    gsap.registerPlugin(ScrollTrigger);

    const topRef = useRef();

    let ctx;
    useEffect(() => {
        ctx && ctx.revert();
        ctx = gsap.context(() => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: topRef.current,
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: true,
                    pin: true,
                    pinSpacing: false,
                    invalidateOnRefresh: true,
                },
            });
            tl.to('.k-left-solid', {
                x: () => window.innerWidth * 0.355 + (937 * 0.2 - window.innerHeight * 0.2),
                opacity: 1,
            });
            tl.to(
                '.k-left-border',
                {
                    x: () => window.innerWidth * 0.355 + (937 * 0.2 - window.innerHeight * 0.2),
                    opacity: 0,
                },
                '<'
            );
            tl.to(
                '.background-left',
                {
                    x: () => window.innerWidth * 0.355 + (937 * 0.2 - window.innerHeight * 0.2),
                    opacity: 1,
                },
                '<'
            );
            tl.to(
                '.k-right-solid',
                {
                    x: () => -(window.innerWidth * 0.35),
                    opacity: 1,
                },
                '<'
            );
            tl.to(
                '.background-right',
                {
                    x: () => -(window.innerWidth * 0.35),
                    opacity: 1,
                },
                '<'
            );

            tl.to(
                '.background-left',
                {
                    x: () => window.innerWidth * 0.9,
                },
                '>'
            );
            tl.to(
                '.k',
                {
                    scale: 0.95,
                    x: () => -(window.innerWidth * 0.02),
                    y: () => -(window.innerHeight * 0.12),
                    rotateX: 23.4,
                    rotateY: 15,
                    rotateZ: -5,
                    transformOrigin: '40% 50%',
                },
                '<'
            );

            tl.to(
                '.kite',
                {
                    opacity: 1,
                },
                '>'
            );
            tl.to(
                '.kewl',
                {
                    opacity: 1,
                },
                '>'
            );
            tl.to(
                '.kudos',
                {
                    opacity: 1,
                },
                '>'
            );
            tl.to(
                '.kite',
                {
                    opacity: 0,
                },
                '+=1'
            );
            tl.to(
                '.kewl',
                {
                    opacity: 0,
                },
                '<'
            );
            tl.to(
                '.kudos',
                {
                    opacity: 0,
                },
                '<'
            );

            tl.to(
                '.k',
                {
                    scale: 0.4,
                    x: 0,
                    y: 0,
                    rotateX: 0,
                    rotateY: 0,
                    rotateZ: -90,
                    transformOrigin: 'center center',
                },
                '>'
            );

            tl.set(
                '.dash1',
                {
                    opacity: 1,
                },
                '>'
            );
            tl.set(
                '.dash2',
                {
                    opacity: 1,
                },
                '<'
            );
            tl.set(
                '.dash-cover',
                {
                    opacity: 1,
                },
                '<'
            );

            tl.to(
                '.k',
                {
                    y: () => window.innerHeight * 0.3,
                },
                '>'
            );
            tl.to(
                '.dash-cover',
                {
                    y: () => window.innerHeight * 0.2,
                },
                '<'
            );
        }, topRef);
        const streamingVideoUrl = process.env.REACT_APP_MOVIES_TOP;
        // video要素を取得してストリーミングURLを設定
        const videoElement = document.getElementById('topvideo');
        videoElement.src = streamingVideoUrl;
    }, []);

    return (
        <div class="top" ref={topRef} id="screentop">
            <div class="top-text">
                <div class="loop-wrapper">
                    <p class="looptext">I never did a day’s work in my life. It was all fun.</p>
                    <p class="looptext follow">I never did a day’s work in my life. It was all fun.</p>
                </div>
                <div class="loop-wrapper down">
                    <p class="looptextx">I never did a day’s work in my life. It was all fun.</p>
                    <p class="looptextx followx">I never did a day’s work in my life. It was all fun.</p>
                </div>
                <p>思考を加速させろ</p>
            </div>
            <div class="video-container">
                <div class="top filter"></div>
                <video id="topvideo" autoPlay loop muted playsInline loading="lazy"></video>
            </div>
            <div class="wrapper">
                <div class="k">
                    <div class="k-left">
                        <div class="k-left-solid"></div>
                        <div class="k-left-border"></div>
                    </div>
                    <div class="k-right">
                        <div class="k-right-solid"></div>
                    </div>
                </div>
            </div>
            <div class="background-right">
                <div class="bg-tri"></div>
                <div class="bg-sq"></div>
            </div>
            <div class="background-left"></div>
            <div class="kite">
                <div class="path-kite">
                    <svg id="svg" width="300" height="100">
                        <path
                            d="
                            M 10,90
                            L 80,50
                            L 300, 50"
                            stroke="#eee"
                            fill="none"
                        ></path>
                    </svg>
                    <h1 class="policy-head">KITE</h1>
                </div>
                <div class="policy-text">
                    <span>高く舞う、舞い上がる</span>
                    <br />
                    私たちは、高度情報化社会の中、凧のように高く舞い上がるということを目標に、毎日にinnovationを起こしています。
                </div>
            </div>
            <div class="kewl">
                <div class="path">
                    <h1 class="policy-head">KEWL</h1>
                    <svg id="svg" width="300" height="100">
                        <path
                            d="
                            M 50,50
                            L 280, 50"
                            stroke="#eee"
                            fill="none"
                        ></path>
                    </svg>
                </div>
                <div class="policy-text">
                    <span>カッコイイ、クール(スラング)</span>
                    <br />
                    私たちは常にカッコよくありたい。どんな時でも遊び心を忘れず、スラングを生み出していくような若者心を持ち、日々revolutionしています。
                </div>
            </div>
            <div class="kudos">
                <div class="path">
                    <svg id="svg" width="300" height="100">
                        <path
                            d="
                            M 20,50
                            L 80, 50"
                            stroke="#eee"
                            fill="none"
                        ></path>
                    </svg>
                    <h1 class="policy-head">KUDOS</h1>
                </div>
                <div class="policy-text">
                    <span>称賛、賛辞、栄光、名声</span>
                    <br />
                    皆に称賛され、喜ばれたい。そして、栄光を掴み社会に大きな貢献をもたらしたい。そのマインドを持ち続けながらworkしています。
                </div>
            </div>
            <div class="dash">
                <div class="dash1"></div>
                <div class="dash2"></div>
            </div>
            <div class="dash-cover"></div>
        </div>
    );
};
