import React, { useEffect, useRef } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { mediaQuery, useMediaQuery } from './UseMediaQuery';

export const TopSp = () => {
    const isSpTop = useMediaQuery(mediaQuery.spTop);
    const targetRef = useRef(null);
    const videoRef = useRef(null);
    useIntersectionObserver(targetRef, videoRef);
    const videoSourceSp = process.env.REACT_APP_MOVIES_TOP_SP;
    const videoSourcePc = process.env.REACT_APP_MOVIES_TOP;
    useEffect(() => {
        // Set the video source when it's available
        if (isSpTop) {
            if (videoRef.current) {
                videoRef.current.src = videoSourceSp;
            }
        } else {
            if (videoRef.current) {
                videoRef.current.src = videoSourcePc;
            }
        }
    }, [videoSourceSp, videoSourcePc]);

    return (
        <div className="top" id="screentop">
            <div className="top-text">
                <div className="loop-wrapper">
                    <p className="looptext-sp">I never did a day’s work in my life. It was all fun.</p>
                    <p className="looptext-sp follow-sp">I never did a day’s work in my life. It was all fun.</p>
                </div>
                <div className="loop-wrapper down">
                    <p className="looptextx-sp">I never did a day’s work in my life. It was all fun.</p>
                    <p className="looptextx-sp followx-sp">I never did a day’s work in my life. It was all fun.</p>
                </div>
                <div className="top-text-title">思考を加速させろ</div>
            </div>
            <div className="video-container" ref={targetRef}>
                <div className="top filter"></div>
                <video className="fullscreen-video" ref={videoRef} autoPlay loop muted playsInline loading="lazy"></video>
            </div>
            <div className="separater"></div>
        </div>
    );
};
