import React, { useEffect, useRef, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

export const CompanyInfo = () => {
    return (
        <div class="company-info" id="CompanyInfo">
            <div class="breadcrumbs">
                <HashLink to="/#screentop" className="">
                    home
                </HashLink>
                <span>　>　</span>
                <span>Company Info</span>
            </div>
            <h1>Company Info</h1>
            <div class="company-info-area">
                <div class="company-info-midashi-wrap">
                    <div class="company-info-midashi">会社名</div>
                    <div class="company-info-detail">株式会社K.Platinum(ケープラチナ)</div>
                </div>
                <div class="company-info-midashi-wrap">
                    <div class="company-info-midashi">所在地</div>
                    <div class="company-info-detail">
                        〒170-0013
                        <br />
                        東京都豊島区東池袋1丁目34番5号
                        <br />
                        いちご東池袋ビル 6階
                    </div>
                </div>
                <div class="company-info-midashi-wrap">
                    <div class="company-info-midashi">代表者名</div>
                    <div class="company-info-detail">沼田 海斗</div>
                </div>
                <div class="company-info-midashi-wrap">
                    <div class="company-info-midashi">資本金</div>
                    <div class="company-info-detail">2,000,000円</div>
                </div>
                <div class="company-info-midashi-wrap">
                    <div class="company-info-midashi">設立年月</div>
                    <div class="company-info-detail">2023年3月20日</div>
                </div>
            </div>
        </div>
    );
};
